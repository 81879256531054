<div class="wrapper">
  <aside [class.show]="show">
    <div class="d-flex flex-column align-items-center bg-header p-4">
      <img style="height: 70px" src="assets/logo.png" alt="logo" />
      <h5 class="mt-2">{{ appName }}</h5>
    </div>
    <nav>
      @for (m of menus; track m) {
        @if ((m.rights === undefined || hasRight(m.rights)) && (!m.module || (m.module && hasModuleEnabled(m.module)))) {
          <div class="item">
            @if (m.nameMS === undefined) {
              <div class="item-title">
                {{ showAlternateNames && m.alternateName !== undefined ? ('menu.' + m.alternateName | translate) : ('menu.' + m.name | translate) }}
              </div>
            }
            @if (m.nameMS !== undefined) {
              <div class="item-title">
                {{ m.nameMS | ms }}
              </div>
            }
            @for (sousm of m.links; track sousm) {
              @if ((!sousm.module || (sousm.module && hasModuleEnabled(sousm.module))) && (sousm.rights === undefined || hasRight(sousm.rights))) {
                @if (sousm.routerActive === undefined) {
                  <a
                    class="sub-item ms-1"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: sousm.exact }"
                    [routerLink]="sousm.url"
                    (click)="hideMenu()"
                    >
                    <span class="text-center d-inline-block" style="min-width: 20px"><i [ngClass]="sousm.icon"></i></span>
                    {{ showAlternateNames && sousm.alternateName !== undefined ? ('menu.' + sousm.alternateName | translate) : ('menu.' + sousm.name | translate) }}
                  </a>
                }
                @if (sousm.routerActive !== undefined) {
                  <a class="sub-item" [class.active]="sousm.routerActive()" [routerLink]="sousm.url" (click)="hideMenu()">
                    <span class="text-center d-inline-block" style="min-width: 20px"><i [ngClass]="sousm.icon"></i></span>
                    {{ showAlternateNames && sousm.alternateName !== undefined ? ('menu.' + sousm.alternateName | translate) : ('menu.' + sousm.name | translate) }}
                  </a>
                }
              }
            }
          </div>
        }
      }
      <a class="item sub-item ms-1 mt-4" href="{{ foUrl }}">
        <i class="fa-light fa-mobile-alt me-2"></i>
        {{ 'menu.Frontoffice' | translate }}
      </a>
      <a class="item sub-item ms-1 mt-0" routerLinkActive="active" routerLink="/gdpr">
        <i class="fa-light fa-shield-alt me-2"></i>
        {{ 'menu.Politique de confidentialité' | translate }}
      </a>
      <a class="item sub-item ms-1 mt-0 fs-4 mb-3" routerLinkActive="active" routerLink="/support">
        <i class="fa-light fa-headset me-2"></i>
        {{ 'menu.Support' | translate }}
      </a>
      @if (clientEnv.isDemo() || clientEnv.isDeveloppmentEnv() || clientEnv.isRecetteEnv()) {
        <a class="item sub-item mt-0 fs-4 mb-3" routerLinkActive="active" routerLink="/academy">
          <i class="fa-light fa-graduation-cap me-2"></i>
          {{ 'menu.Academie' | translate }}
        </a>
      }
    </nav>
  </aside>
  <div class="d-flex flex-column flex-grow-1">
    <header>
      <div class="menu-button" (click)="toggleMenu()">
        <i class="fa-light fa-bars"></i>
      </div>
      <div class="d-flex align-items-center btn-group">
        <div class="pointer px-2 py-1 me-2" (click)="toggleDarkMode()">
          <i class="fa-light" [class.fa-moon]="isDarkMode" [class.fa-sun]="!isDarkMode"></i>
        </div>
        <!--<h3>TITRE</h3>-->
        <div class="menu-language px-15 py-15 me-3" ngbDropdown placement="bottom-right">
          <div class="pointer" ngbDropdownToggle>
            {{ currentLang }}
          </div>
          <div ngbDropdownMenu>
            <h6 class="dropdown-header">{{ 'global.Langue' | translate }}</h6>
            <div style="max-height: 200px; overflow: auto">
              @for (lang of sortedAvailableLanguages; track lang) {
                @if (lang !== currentLang) {
                  <div ngbDropdownItem>
                    <div>
                      <button [class.text-success]="translate.currentLang == lang" ngbDropdownItem (click)="changeLanguage(lang)">
                        {{ 'common.languages.' + lang | translate }}
                      </button>
                    </div>
                  </div>
                }
              }
            </div>
          </div>
        </div>
        <div class="me-1 text-info-hover py-15" ngbDropdown placement="bottom-right">
          <div class="pointer" ngbDropdownToggle>
            <i class="fa-light fa-user-circle me-1"></i>
            @if (authService.userBundle) {
              {{ authService.userBundle.user.firstName + ' ' + authService.userBundle.user.lastName }}
            }
            @if (authService.userBundle === null) {
              {{ 'back-layout.Déconnecté' | translate }}
            }
          </div>
          <div ngbDropdownMenu>
            <div ngbDropdownItem>
              <button ngbDropdownItem (click)="logout()">{{ 'back-layout.Se déconnecter' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main (click)="hideMenu()">
      <router-outlet></router-outlet>
      <ic2-toastr-container class="position-fixed bottom-0 start-special-menu translate-middle-x" style="z-index: 5"></ic2-toastr-container>
    </main>
  </div>
</div>
@if (clientEnv.isDemo() || clientEnv.isDeveloppmentEnv() || clientEnv.isRecetteEnv()) {
  <hiji-lemon />
}
